<div class="login-layout" nz-flex nzJustify="center" nzAlign="center">
  <nz-card class="login-form shadow-6 inner-x-xxs inner-top-xs outer-micro" [nzActions]="[actionEllipsis]">
    <div class="text-center">
      <!--<img class="pangea-logo" alt="Pangea logo" src="/assets/images/TNP_logo_DARK.png" />-->
      <h1 class="outer-y-xxs logo">DXTER</h1>
    </div>
    <form nz-form [formGroup]="validateForm" class="inner-y-xxs" (ngSubmit)="submitForm()">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your username!">
          <nz-input-group nzPrefixIcon="user" nzSize="large">
            <input type="email" nz-input formControlName="email" placeholder="Email" data-testid="usernameField" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="outer-top-xxs">
        <nz-form-control nzErrorTip="Please input your Password!">
          <nz-input-group nzPrefixIcon="lock" nzSize="large">
            <input
              type="password"
              nz-input
              formControlName="password"
              placeholder="Password"
              data-testid="passwordField" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <button
        nz-button
        class="outer-top-xxs login-form-button login-form-margin"
        data-testid="loginButton"
        [nzType]="'primary'"
        nzBlock
        nzSize="large"
        nzShape="round"
        [disabled]="loading()">
        Log in
      </button>
    </form>
    @if (loading()) {
      <div class="text-center">
        <nz-spin nzSimple></nz-spin>
      </div>
    }
  </nz-card>
  <ng-template #actionEllipsis>
    <a (click)="forgotPasswordClick()"> Forgot password </a>
  </ng-template>
</div>
